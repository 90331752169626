<template>
    <div class="user-edit">
        <div class="main-h1">Создание пользователя</div>
        <div class="card">
            <div class="card-body">
                <form v-on:submit.prevent>
                    <table class="table">
                        <tr>
                            <td>Login:</td>
                            <td><input v-model="data.login" required></td>
                        </tr>
                        <tr>
                            <td>Имя:</td>
                            <td><input v-model="data.name" required></td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td><input v-model="data.email" required></td>
                        </tr>
                        <tr>
                            <td>Номер телефона:</td>
                            <td><input v-model="data.phone" required></td>
                        </tr>
                        <tr>
                            <td>Роли:</td>
                            <td>
                                <label v-for="role in roles">
                                    <input type="checkbox" :value="role.id" v-model="data.roles"> {{role.title}}
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>Пароль</td>
                            <td><input v-model="data.password" type="password" required></td>
                        </tr>
                        <tr>
                            <td>Повторите пароль</td>
                            <td><input v-model="data.password2" type="password" required></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <button class="btn green" @click="sendData" :disabled="requestInProgress">Создать</button>
                            </td>
                        </tr>
                    </table>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "userCreate",
    data() {
        return {
            data:{
                roles:[]
            },
            roles:[],
            requestInProgress:false,
        }
    },
    methods: {
        getRoles() {
            this.$api.get(`user/roles`).then(res => {
                if (res.data.success) {
                    this.roles = res.data.data
                }
            })
        },
        sendData(){
            this.requestInProgress = true;
            this.$api.post(`user`,this.data).then(res => {
                this.requestInProgress = false;
                if (res.data.success) {
                    this.$store.commit('showNotify',{title:'Пользователь создан',content:'Пользователь успешно создан.',type:'success'})
                    this.$router.push('/panel/user');
                } else {
                    this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'})
                }
            })
        },
    },
    created() {
        this.getRoles();
    }
}
</script>

<style lang="scss" scoped>
    .user-edit {

    }
</style>